import { combineReducers } from "redux";
import { REVIEWS, REVIEWS_SUCCESS } from "../constants/reviews";
import { HYDRATE } from "next-redux-wrapper";

export function reviews(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.reviews.reviews;
    case REVIEWS:
      return action.data;
    default:
      return state;
  }
}

export function reviewsSuccess(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.reviews.reviewsSuccess;
    case REVIEWS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export const reviewsReducers = () =>
  combineReducers({
    reviews: reviews,
    reviewsSuccess: reviewsSuccess,
  });
